<template>
  <div
    class="modal fade"
    :id="id"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
      :class="{
        'modal-lg': large,
        'modal-xl': extraLarge,
        'modal-fullscreen': fullScreen,
      }"
    >
      <div class="modal-content" :class="{ 'h-100': h100 }">
        <div
          class="modal-header border-0"
          v-if="hasHeader"
          :class="{ 'justify-content-center': centeredTitle }"
        >
          <h2 class="h2 mb-0 text-header text-center">{{ title }}</h2>
          <i
            type="button"
            class="fas fa-times text-muted"
            data-bs-dismiss="modal"
            aria-label="Close"
            :class="{ 'close-icon': centeredTitle }"
            @click="handleOnClose"
          ></i>
        </div>
        <div class="modal-body border-0">
          <slot name="body"></slot>
        </div>
        <div class="modal-footer border-0" v-if="this.$slots.footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
    title: String,
    onClose: Function,
    large: {
      default: false,
      type: Boolean,
    },
    extraLarge: {
      default: false,
      type: Boolean,
    },
    hasHeader: {
      default: true,
      type: Boolean,
    },
    h100: {
      default: false,
      type: Boolean,
    },
    fullScreen: {
      default: false,
      type: Boolean,
    },
    centeredTitle: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    handleOnClose() {
      this.$emit("onClose");
    },
  },
};
</script>

<style scoped>
.close-icon {
  position: absolute;
  right: 20px;
}
</style>
