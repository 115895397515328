<template>
  <ModalComponent
    id="subscription_modal"
    title="Assinatura Premium"
    :fullScreen="fullScreen"
    :centeredTitle="fullScreen"
  >
    <template v-slot:body>
      <div :class="{ container: fullScreen }">
        <div :class="{ row: fullScreen }">
          <div :class="{ 'col-12 col-md-8 offset-md-2': fullScreen }">
            <div class="card bg-warning-soft p-6">
              <div class="d-flex justify-content-center align-items-center">
                <i class="fa-solid fa-crown display-1 text-warning"></i>
              </div>
            </div>
            <p class="text-header">Torne-se um assinante</p>
            <p class="text-paragraph">
              O membro premium tem dezenas de benefícios, além de um atendimento
              personalizado para seu paciente.
            </p>

            <hr />

            <p class="text-header">Principais benefícios</p>
            <div class="d-flex align-items-center mb-3">
              <i
                class="fa-solid fa-check-circle text-primary text-header me-2"
              />
              <p class="text-paragraph mb-0">
                Detalhes das especialidades médicas
              </p>
            </div>
            <div class="d-flex align-items-center mb-3">
              <i
                class="fa-solid fa-check-circle text-primary text-header me-2"
              />
              <p class="text-paragraph mb-0">Criação de prescrições médicas</p>
            </div>
            <div class="d-flex align-items-center mb-3">
              <i
                class="fa-solid fa-check-circle text-primary text-header me-2"
              />
              <p class="text-paragraph mb-0">Criação de receituários</p>
            </div>

            <hr />

            <p class="text-paragraph">
              <span
                class="text-subtitle text-primary cursor-pointer"
                @click="openModal('terms_of_use')"
                >Termos de uso</span
              >
              e
              <span
                class="text-subtitle text-primary cursor-pointer"
                @click="openModal('privacy_policy')"
                >Política de privacidade</span
              >
            </p>
            <button class="btn btn-primary w-100" @click="handleSubscribe">
              Testar 7 dias grátis
            </button>
          </div>
        </div>
      </div>
    </template>
  </ModalComponent>
</template>

<script>
import { Modal } from "bootstrap";

export default {
  data() {
    return {
      fullScreen: false,
    };
  },
  mounted() {
    this.subscriptionModal = new Modal(
      document.getElementById("subscription_modal")
    );
    this.$eventBus.$on("openSubscriptionModal", (fullScreen) => {
      this.fullScreen = fullScreen;
      this.subscriptionModal.toggle();
    });
  },
  methods: {
    openModal(key) {
      this.$eventBus.$emit("openAboutAppModal", { key });
    },
    handleSubscribe() {
      this.subscriptionModal.toggle();
      this.$router.push(this.$paths.subscription);
    },
  },
};
</script>
